import { toast } from 'react-toastify';
import LeadsService from 'src/services/leads';

export async function openPopupMetaLeads(customerId, setLoadingMeta) {
  const width = 600;
  const height = 800;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  try {
    setLoadingMeta(true);
    const url = await LeadsService.getUrlMetaLeads(customerId);

    const popupWindow = window.open(
      url,
      'Facebook',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!popupWindow) {
      setLoadingMeta(false);
      toast.error(
        'Não foi possível abrir a popup. Verifique o bloqueador de popups.'
      );
      return;
    }

    // Função para verificar o status da autenticação
    const checkAuthStatus = async () => {
      try {
        const currentUrl = popupWindow.location.href;

        // Extrai os parâmetros da URL
        const urlParams = new URLSearchParams(new URL(currentUrl).search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        // Verifica se a URL contém os parâmetros de autenticação
        if (code && state) {
          await LeadsService.getCallbackMetaLeads(code, state).then((res) => {
            if (res.status === 200) {
              toast.success('Integração com Facebook realizada com sucesso!');
            } else {
              toast.error('Erro ao integrar com Facebook, contate o suporte.');
            }
          });

          // Fecha a popup e limpa o intervalo
          popupWindow.close();
          clearInterval(checkInterval);
          setLoadingMeta(false);
        }
      } catch (error) {
        // Erros de CORS são esperados e podem ser ignorados
        // Ocorrem quando a popup navega para domínios diferentes
      }
    };

    // Configura o intervalo para verificar a autenticação
    const checkInterval = setInterval(async () => {
      // Verifica se a popup foi fechada pelo usuário
      if (popupWindow.closed) {
        clearInterval(checkInterval);
        setLoadingMeta(false);
        toast.warning(
          'Popup do Facebook fechada antes de finalizar a integração'
        );
        return;
      }

      // Verifica o status da autenticação
      await checkAuthStatus();
    }, 500);
  } catch (error) {
    console.error('Erro na integração com Facebook:', error);
    setLoadingMeta(false);
    toast.error('Erro ao obter URL do Facebook, contate o suporte.');
  }
}
