import poliApiGatewayRequest from "src/utils/poliApiGatewayRequest";

class LeadsService {
  static async getUrlMetaLeads(customerId: number) {
    const url = `/leads/meta/login?customer_id=${customerId}`;
    const response = await poliApiGatewayRequest.get(url);
    return response?.data?.url;
  }

  static async getCallbackMetaLeads(code: string, state: string) {
    const url = `/leads/meta/callback?code=${code}&state=${state}`;
    const response = await poliApiGatewayRequest.get(url);
    return response;
  }
}

export default LeadsService;
